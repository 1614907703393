import React, { useState } from "react";
import { graphql } from "gatsby";

import SEO from "../components/SEO";
import SignUpLayout from "../components/SignUpLayout";
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";
import { ErrorRenderer, Field, TextareaField, useField } from "../components/sign-up/common";
import {
  validateFirstName,
  validateLastName,
  validateEmail,
  validatePhone,
} from "../components/sign-up/CoordPart.js";
import homepage from "../lib/homepage";
import { SuccessCircle } from "../components/svg-assets/Icons";

const SignUpPage = () => {
  const { t } = useTranslation();

  const firstName = useField("", validateFirstName);
  const lastName = useField("", validateLastName);
  const email = useField("", validateEmail);
  const phone = useField("", validatePhone);
  const message = useField("", () => null);

  const allFields = [firstName, lastName, email, phone, message];

  const [globalError, setGlobalError] = useState(null);
  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState(false);

  const sendMessage = (firstName, lastName, email, phone, message) => {
    setSending(true);

    // Actually send the message
    homepage.sendContact(
      {
        contactName: `${firstName} ${lastName}`,
        email,
        phone,
        message,
      },
      () => {
        setSending(false);
        setSent(true);
      },
      () => {
        setSending(false);
        setGlobalError({ i18nKey: "contact.errors.generic-api-error" });
      }
    );
  };

  return (
    <SignUpLayout>
      <SEO title={t("contact.html-title")} />
      {!sent ? (
        <div className="su-main fl-blue-context">
          <div className="fl-tag fl-naked-tag">
            <Trans i18nKey="contact.tag" />
          </div>
          <h2>
            <Trans i18nKey="contact.title" />
          </h2>

          <div className="su-two-fields">
            <Field
              label={t("contact.first-name.label")}
              field={firstName}
              id="first-name"
              autoComplete="given-name"
              placeholder={t("contact.first-name.placeholder")}
            />
            <Field
              label={t("contact.last-name.label")}
              field={lastName}
              id="last-name"
              autoComplete="given-name"
              placeholder={t("contact.last-name.placeholder")}
            />
          </div>

          <Field
            label={t("contact.email.label")}
            field={email}
            id="email"
            autoComplete="email"
            placeholder={t("contact.email.placeholder")}
          />

          <Field
            label={t("contact.phone.label")}
            field={phone}
            id="phone"
            autoComplete="tel"
            placeholder={t("contact.phone.placeholder")}
          />

          <TextareaField label={t("contact.message.label")} field={message} />

          <ErrorRenderer errorMessage={globalError} />

          <p className="su-next-wrapper">
            <button
              className={`su-next ${sending ? "su-next--saving" : ""}`}
              disabled={allFields.some(field => Boolean(field.validator()))}
              onClick={() => sendMessage(...allFields.map(field => field.value))}>
              <Trans i18nKey="contact.send" />
            </button>
          </p>
        </div>
      ) : (
        <div className="su-main fl-blue-context">
          <div className="fl-tag fl-naked-tag">
            <Trans i18nKey="contact.thank-you.tag" />
          </div>
          <h2>
            <Trans i18nKey="contact.thank-you.title" />
          </h2>

          <div className="su-success-circle">
            <SuccessCircle />
          </div>

          <p className="su-thank-you-message">
            <Trans i18nKey="contact.thank-you.message" />
          </p>

          <p className="su-next-wrapper">
            <Link to="/" className="su-next">
              <Trans i18nKey="sign-up.back-to-website" />
            </Link>
          </p>
        </div>
      )}
    </SignUpLayout>
  );
};

export default SignUpPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
